<template lang="html">
  <section>
    <button class="button is-primary" type="button" name="button" @click="showModal = true">Request Demo</button>
    <b-modal v-model="showModal" full-screen>
      <div class="modal-card">
        <header class="modal-card-head center-content">
          <h1 class="has-text-centered title is-size-3">Demo Request</h1>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column is-4 is-offset-4">
              <p class="has-text-centered">Interested in how we will make your life simpler and business more profitable?</p>
              <p class="is-size-7 has-text-centered">Fill the form below and we’ll contact you as soon as possible</p>
              <br>
              <b-field grouped>
                <b-field label="First Name" expanded>
                  <b-input id="fname" name="fname" placeholder="First Name" v-model="demoRequest.firstName"></b-input>
                </b-field>
                <b-field label="Last Name" expanded>
                  <b-input id="lname" name="lname" placeholder="Last Name" v-model="demoRequest.lastName"></b-input>
                </b-field>
              </b-field>

              <b-field label="Email" expanded>
                <b-input id="email" name="email" placeholder="Email" v-model="demoRequest.email"></b-input>
              </b-field>

              <b-field label="Company" expanded>
                <b-input id="company" name="company" placeholder="Company" v-model="demoRequest.companyName"></b-input>
              </b-field>

              <b-field label="Business Type" expanded>
                <b-select id="business-type" name="business-type" placeholder="Select Business Type" v-model="demoRequest.businessType" required expanded>
                  <option
                    v-for="business in businessTypes"
                    :value="business.type"
                    :key="business.type">
                    {{ business.type }}
                  </option>
                </b-select>
              </b-field>

              <b-field label="Interest" expanded>
                <b-select id="interest" name="interest" placeholder="Select Interest" v-model="demoRequest.interest" required expanded>
                  <option
                    v-for="p in products"
                    :value="p.name"
                    :key="p.name">
                    {{ p.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot center-content">
          <button class="button is-light" name="button" @click="showModal = false">Cancel</button>
          <button class="button is-success" type="submit" name="button" @click.prevent="submitRequest();">Schedule Demo</button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { ToastProgrammatic as Toast } from 'buefy'
import axios from 'axios';
import logo from 'images/on_dark.png';
export default {
  data() {
    return {
      showModal: false,
      logo,
      selectedProduct: '',
      products: [
        {
          'name': 'SimpleVET'
        },
        {
          'name': 'Target Weight Toolkit'
        }
      ],
      businessTypes: [
        {
          'type': 'Grower/Integrator'
        },
        {
          'type': 'Veterinary Practice'
        },
        {
          'type': 'Feed Distributor'
        },
        {
          'type': 'Other'
        },
      ],
      demoRequest:
        {
          'firstName': null,
          'lastName': null,
          'companyName': null,
          'email': null,
          'businessType': null,
          'interest': null,
        }
    }
  },
  methods: {
    checkObject(obj) {
      for (var attribute in obj) {
        if (obj[attribute] === null || obj[attribute] === '') {
          return false
        }
      }
      return true
    },
    submitRequest(){
      if (this.checkObject(this.demoRequest) === true) {
        axios({
          method: 'POST',
          url: '/request_demo',
          data: {demoRequest: this.demoRequest}
        }).then(response => {
            if(response.status === 200) {
              this.showModal = false
              Toast.open('Demo Request Submitted!')
              this.demoRequest =
                {
                  'firstName': null,
                  'lastName': null,
                  'companyName': null,
                  'email': null,
                  'businessType': null,
                  'interest': null,
                }
            } else {
              Toast.open('There was an error submitting Your Demo Request')
            }
        });
      } else {
        Toast.open('There was an error submitting Your Demo Request')
      }
    }
  }
}
</script>

<style media="screen">
  .center-content {
    justify-content: center;
    text-align: center;
    display: flex;
  }
</style>
