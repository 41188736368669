<template lang="html">
  <div>
    <section class="hero tagline">
    	<div class="hero-body">
    		<div class="level">
    			<div class="level-item">
    				<div class="is-size-5 has-text-white has-text-centered">
    					Regulatory compliance shouldn't be complex
    				</div>
    			</div>
    		</div>
    		<div class="level">
    			<div class="level-item">
    				<div class="has-text-centered">
    					<h3 class="has-text-white title is-size-4">Keep it simple with us.</h3>
    				</div>
    			</div>
    		</div>
    		<div class="tile is-child">
    			<div class="level is-centered">
    				<div class="level-left is-centered">
    					<div class="level-item">
    						<RequestModal class="footer-button"/>
    					</div>
    					<div class="level-item">
    						<ContactModal/>
    					</div>
    				</div>
    			</div>
    		</div>
    	</div>
    </section>

    <section class="hero is-sas-navy">
    	<div class="hero-body">
    		<div class="level">
    			<div class="level-item">
    				<div class="has-text-centered">
    					<a href="/" class="">
    			      <img alt="Simple Ag Logo" src="/assets/on_dark.png">
    			    </a>
    				</div>
    			</div>
    		</div>
    		<div class="level">
    			<div class="level-item has-text-centered">
    				<p class="has-text-grey">Copyright &#169; 2016-22 Simple Ag Solutions, Inc.</p>
    			</div>
    		</div>
    	</div>
    </section>
  </div>
</template>

<script>
import RequestModal from '../modals/request_button.vue';
import ContactModal from '../modals/contact_button.vue';
export default {
  components: {
    RequestModal,
    ContactModal
  }
}
</script>

<style media="screen">
  .footer-button button {
    outline-style: solid !important;
    outline-width: 1px !important;
    outline-color: white !important;
    background-color: transparent !important;
  }
  .footer-button button:hover {
    background-color: white !important;
    color: #1E263E !important;
  }
</style>
