import Vue from 'vue';
import Footer from '../modules/shared/footer.vue';
import Buefy from 'buefy';
import axios from 'axios';

Vue.use(Buefy)

document.addEventListener('DOMContentLoaded', () => {
  var element = document.getElementById("vue-footer")
  if (element != null) {
    const app = new Vue({
      el: element,
      render: h => h(Footer),
      data: {},
    }).$mount()
  }
})
