<template lang="html">
  <section>

  <button class="button is-info" type="button" name="button" @click="showModal = true">Contact Us</button>
  <b-modal v-model="showModal" full-screen>
    <div class="modal-card">
      <header class="modal-card-head center-content">
        <h1 class="has-text-centered title is-size-3">Have a Question?</h1>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column is-4 is-offset-4">
            <p class="has-text-centered">Our team will gladly respond to you</p>
            <p class="is-size-7 has-text-centered">Fill the form below and we’ll contact you as soon as possible</p>
            <br>
            <b-field grouped>
              <b-field label="First Name" expanded>
                <b-input id="fname" name="fname" placeholder="First Name" v-model="contactForm.firstName"></b-input>
              </b-field>
              <b-field label="Last Name" expanded>
                <b-input id="lname" name="lname" placeholder="Last Name" v-model="contactForm.lastName"></b-input>
              </b-field>
            </b-field>

            <b-field label="Email" expanded>
              <b-input id="email" name="email" placeholder="Email" v-model="contactForm.email"></b-input>
            </b-field>

            <b-field label="Business Type" expanded>
              <b-select id="business-type" name="business-type" placeholder="Select Business Type" v-model="contactForm.businessType" required expanded>
                <option
                  v-for="business in businessTypes"
                  :value="business.type"
                  :key="business.type">
                  {{ business.type }}
                </option>
              </b-select>
            </b-field>


            <b-field label="Message" expanded>
              <b-input type="textarea" placeholder="Message" name="message" id='message' v-model="contactForm.message" required></b-input>
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot center-content">
        <button class="button is-light" name="button" @click="showModal = false">Cancel</button>
        <button class="button is-success" type="submit" name="button" @click.prevent="submitRequest();">Submit</button>
      </footer>
    </div>
  </b-modal>
  </section>
</template>

<script>
import { ToastProgrammatic as Toast } from 'buefy'
import axios from 'axios';
import logo from 'images/on_dark.png';
export default {
  data() {
    return {
      showModal: false,
      logo,
      businessTypes: [
        {
          'type': 'Grower/Integrator'
        },
        {
          'type': 'Veterinary Practice'
        },
        {
          'type': 'Feed Distributor'
        },
        {
          'type': 'Other'
        },
      ],
      contactForm:
        {
          'firstName': null,
          'lastName': null,
          'email': null,
          'businessType': null,
          'message': null,
        }
    }
  },
  methods: {
    checkObject(obj) {
      for (var attribute in obj) {
        if (obj[attribute] === null || obj[attribute] === '') {
          return false
        }
      }
      return true
    },
    submitRequest(){
      if (this.checkObject(this.contactForm) === true) {
        axios({
          method: 'POST',
          url: '/contact_sales',
          data: {contactForm: this.contactForm}
        }).then(response => {
            if(response.status === 200) {
              this.showModal = false
              Toast.open('Message Sent!')
              this.contactForm =
                {
                  'firstName': null,
                  'lastName': null,
                  'email': null,
                  'businessType': null,
                  'message': null,
                }
            } else {
              Toast.open('There was an error sending your messsage')
            }
        });
      } else {
        Toast.open('There was an error sending your message')
      }
    }
  }
}
</script>

<style media="screen">
  .center-content {
    justify-content: center;
    text-align: center;
    display: flex;
  }
</style>
